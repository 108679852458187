
<script setup>
    import { provide, computed, watch, ref } from "vue";

    import MActionSheetBtn from "mobile.vue.components.ActionSheetBtn.vue";

    const props = defineProps(["modelValue"]);
    const emit = defineEmits(["update:modelValue"]);

    const actions = ref(null);

    const model = computed({
        get() { return props.modelValue },
        set(val) { emit("update:modelValue", val) },
    });

    provide("action-sheet", model);

    function onClickSheet(e) {
        const button = e.target.closest("button");
        if (button && button.classList.contains("afm-action-sheet-btn") && !button.disabled) {
            emit("update:modelValue", false);
        }
    }
    
    watch(() => props.modelValue, () => {
        actions.value.scrollTop = actions.value.scrollHeight;
    });
    
</script>

<template>
    <div>
        <teleport to="body">
            <div>
                <!-- backdrop -->
                <div class="afm-action-sheet-backdrop" :class="{ 'show': modelValue }" style="position: fixed; inset: 0; background-color: rgba(0%, 0%, 0%, 40%); z-index: 2100;" @click="$emit('update:modelValue', false)">
                </div>

                <!-- sheet -->
                <div
                    class="afm-action-sheet-modal"
                    :class="{ 'show': modelValue }"
                    style="position: fixed; left: 0; right: 0; bottom: 0; z-index: 2110;"
                    @click="onClickSheet"
                >
                    <div class="mx-2 mb-2 afm-action-sheet-container" ref="actions">
                        <template v-if="$slots.header">
                            <div class="border-bottom" style="color: rgb(60%, 60%, 60%); font-size: 0.9em; background-color: rgb(96%, 96%, 96%); border-radius: 0.5rem 0.5rem 0 0;">
                                <slot name="header" />
                            </div>
                        </template>
                        <slot />
                    </div>
                    <div class="mx-2 mb-2 afm-action-sheet-container">
                        <MActionSheetBtn class="fw-medium" :text="$t('Cancel')" />
                    </div>
                </div>
            </div>
        </teleport>
    </div>
</template>

<style>
    .afm-action-sheet-container {
        max-height: calc((3em * 10) + 2em);
        overflow-y: auto;

        > .afm-action-sheet-btn {
            position: relative;
            display: flex;
            justify-content: left;
            align-items: center;
            

            color: black;
            background-color: rgb(96%, 96%, 96%);
            width: 100%;
            border: none;
            font-size: 1.0625em;
            font-size: 1.125em;
            color: #3575B3;
            color: #3b82f6;
            color: rgb(20%, 20%, 20%);
            color: #0ea5e9;
            color: var(--bs-black);
            height: 3em;

            padding: 0 1.5rem;

            transition: all 200ms ease-in-out;

            &:not(:last-child) {
                border-bottom: 1px solid rgb(90%, 90%, 90%);
            }
            &:first-child {
                border-top-left-radius: 0.5rem;
                border-top-right-radius: 0.5rem;
            }
            &:last-child {
                border-bottom-left-radius: 0.5rem;
                border-bottom-right-radius: 0.5rem;
            }
            &:active:not(:disabled) {
                background-color: rgb(90%, 90%, 90%);
                transition: all 0ms ease-in-out;
            }
            &:disabled {
                background-color: var(--bs-gray-100);
                color: var(--bs-gray-500);
                pointer-events: none;
            }

            > i {
                position: absolute;
                top: 50%;
                right: 1.5rem;
                transform: translate(0%, -50%);
                font-size: 1.375em;
            }
        }
    }
</style>

<style scoped>
    .afm-action-sheet-backdrop {
        transition: all 300ms ease-in-out;

        pointer-events: none;
        opacity: 0%;

        &.show {
            pointer-events: auto;
            opacity: 100%;
        }
    }

    .afm-action-sheet-modal {
        transition: all 300ms ease-in-out;

        transform: translate(0, 25%);
        opacity: 0%;
        pointer-events: none;

        &.show {
            pointer-events: auto;
            opacity: 100%;
            transform: translate(0, 0);
        }
    }
    
    .raise-enter-from {
        transform: translate(0, 25%);
        opacity: 0%;
    }
    .raise-enter-active {
        transition: all 300ms ease-in-out;
    }
    .raise-enter-to {
    }
    .raise-leave-from {
    }
    .raise-leave-active {
        transition: all 300ms ease-in-out;
    }
    .raise-leave-to {
        transform: translate(0, 25%);
        opacity: 0%;
    }
</style>
